import React from 'react'
import Lifestyle from '../../components/candidateComponents/lifeStyle'
import CandidateLayout from '../../layout/candidateRegisterLayout'

export default function LifeStyle() {
  return (
    <CandidateLayout>
      <div>
        <Lifestyle/>
      </div>
    </CandidateLayout>
  )
}
