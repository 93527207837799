import React from "react";
import UploadDocuments from "../../components/candidateComponents/uploadDocuments";
import CandidateLayout from "../../layout/candidateRegisterLayout";

export default function CandidateDocument() {
  return (
    <CandidateLayout>
      <UploadDocuments />
    </CandidateLayout>
  );
}
