import React from "react";
import MatchingCandidate from "../../../components/adminComponents/matchingExpert/matchingCandidate";
import AdminLayout from "../../../layout/adminLayout";

export default function MatchingexpertCandiadte() {
  return (
    <AdminLayout>
      <div>
        <MatchingCandidate />
      </div>
    </AdminLayout>
  );
}
