import React from "react";
import SuitableMatch from "../../../components/adminComponents/matchingExpert/suitableMatch";
import AdminLayout from "../../../layout/adminLayout";

export default function SuitableMatchPage() {
  return (
    <AdminLayout>
      <div>
        <SuitableMatch />
      </div>
    </AdminLayout>
  );
}
