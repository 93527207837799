import React from "react";
import MatchingBride from "../../../components/adminComponents/matchingExpert/matchingBride";
import AdminLayout from "../../../layout/adminLayout";

export default function MatchBride() {
  return (
    <AdminLayout>
      <div>
        <MatchingBride />
      </div>
    </AdminLayout>
  );
}
