import React from 'react';
import DocumentAttachments from '../../components/adminComponents/documentVerification/documentsAttchments';
import AdminLayout from '../../layout/adminLayout/index';

export default function AdminDocumentattchments() {
  return (
    <AdminLayout>
        <div>
        <DocumentAttachments />
        </div>
    </AdminLayout>
  )
}
