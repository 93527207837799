import React from 'react'
import Resubmittedcandidate from '../../components/adminComponents/documentVerification/resubmittedCandidate'
import AdminLayout from '../../layout/adminLayout'

 const  ResubmittedcandidateAdmin=()=> {
  return (
    <AdminLayout>
        <div>
         <Resubmittedcandidate />
        </div>
    </AdminLayout>
  )
}
export default ResubmittedcandidateAdmin