import React from 'react'
import BgapprovalDetails from '../../components/adminComponents/bgExpert/bgApprovalDetails'
import AdminLayout from '../../layout/adminLayout'

export default function BgApprovalDetails() {
  return (
    <AdminLayout>
        <div>
        <BgapprovalDetails />
        </div>
    </AdminLayout>
  )
}
