import React from "react";
import MatchingBrideFliter from "../../../components/adminComponents/matchingExpert/matchingBrideFliter";
import AdminLayout from "../../../layout/adminLayout";

export default function MatchBrideFliter() {
  return (
    <AdminLayout>
      <div>
        <MatchingBrideFliter />
      </div>
    </AdminLayout>
  );
}
