import React from 'react'
import SuperadminDashboard from '../../components/superAdminComponents/superAdminDashboard'
import SuperadminLayout from '../../layout/superAdminLayout'

export default function SuperAdminDashboard() {
  return (
    <SuperadminLayout>
        <div>
        <SuperadminDashboard />
        </div>
    </SuperadminLayout>
  )
}
