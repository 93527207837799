const maritalStatus = [
  { label: "Unmarried", value: "Unmarried" },
  { label: "Widow/Widower", value: "Widow/Widower" },
  { label: "Divorced", value: "Divorced" },
];
const dietDropdown = [
  { label: "Veg", value: "Veg" },
  { label: "Vegan", value: "Vegan" },
  { label: "Jain", value: "Jain" },
  { label: "Eggitarian", value: "Eggitarian" },
  { label: "Non-veg", value: "Non-veg" },
];
const ageSelect = [
  { label: "20-25", value: "20-25" },
  { label: "30-35", value: "30-35" },
  { label: "40-45", value: "40-45" },
  { label: "50-55", value: "50-55" },
  { label: "60-65", value: "60-65" },
  { label: "70-75", value: "70-75" },
];
const hfnYears = 1095;

export { maritalStatus, dietDropdown, hfnYears, ageSelect };
