import React from "react";
import RequestWedding from "../../../components/adminComponents/matchingExpert/requestWedding";
import AdminLayout from "../../../layout/adminLayout";

export default function RequestForWedding() {
  return (
    <AdminLayout>
      <div>
        <RequestWedding />
      </div>
    </AdminLayout>
  );
}
