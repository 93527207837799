import React from 'react'
import  Registeredcandidate from '../../components/adminComponents/documentVerification/registeredCandidate'
import AdminLayout from '../../layout/adminLayout'

export default function RegisterAdminCandidate() {
  return (
    <AdminLayout>
        <div>
        <Registeredcandidate />
        </div>
    </AdminLayout>
  )
}
