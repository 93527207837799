const configData = process.env;

const config = {};

try {
  // config.awGtagCode = configData.AWS_GTAG_CODE || 'AW-854824176/pzEJCL2dj_8CEPCpzpcD';
  config.srcmApi =
  configData.REACT_APP_RCM_SEARCH_API_URI ||
  "https://static-gatsby-qa.web.app/srcmapi/";
config.srcmApiv2 =
  configData.REACT_APP_SRCM_API_URI || "https://profile.srcm.net/api/v2";
config.apiURL =
configData.REACT_APP_API_URI ||
"https://writenodeedu.heartfulness.org/proposaleportal-qa/public/api/v1/";
config.evalutionURL =
configData.REACT_APP_API_BASE_URI ||
"https://writenodeedu.heartfulness.org/proposaleportal-qa/public/";
  config.clientId =
  configData.REACT_APP_CLIENT_ID ||
  "sNoCucDYc1ok5D8HzktKJUROtXGlD49tSGIPiXzn";

} catch {
  // config.awGtagCode = 'AW-854824176/pzEJCL2dj_8CEPCpzpcD';
  config.srcmApi =
  configData.REACT_APP_API_SRCM_API_URI ||
  "https://static-gatsby-qa.web.app/srcmapi/";
config.srcmApiv2 =
  configData.REACT_APP_API_SRCM_API_URI || "https://profile.srcm.net/api/v2";
config.apiURL =
  configData.REACT_APP_API_URI ||
  "https://writenodeedu.heartfulness.org/proposaleportal-qa/public/api/v1/";
config.evalutionURL =
  configData.REACT_APP_API_BASE_URI ||
  "https://writenodeedu.heartfulness.org/proposaleportal-qa/public/";
  config.clientId =
  configData.REACT_APP_CLIENT_ID ||
  "sNoCucDYc1ok5D8HzktKJUROtXGlD49tSGIPiXzn";
}

config.imageUrl = "";

module.exports = config;
