import React from 'react';
import Reasonofreject from '../../components/adminComponents/documentVerification/reasonOfReject';
import AdminLayout from '../../layout/adminLayout';

export default function AdminReasonofreject() {
  return (<AdminLayout>
        <div>
        <Reasonofreject />
        </div>
    </AdminLayout>
  )
}
