import React from 'react';
import Candidateverification from '../../components/adminComponents/documentVerification/candidateVerification';
import AdminLayout from '../../layout/adminLayout';

export default function AdminCandidateVerificication() {
  return (
    <AdminLayout>
        <div>
        <Candidateverification />
        </div>
    </AdminLayout>
  )
}
