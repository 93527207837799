import React from 'react'
import BgverifiedCandidate from '../../components/adminComponents/bgExpert/bgVerifiedCandidate'
import AdminLayout from '../../layout/adminLayout'

export default function Bgcandidateverification() {
  return (
    <AdminLayout>
        <div>
        <BgverifiedCandidate />
        </div>
    </AdminLayout>
  )
}
